/* Style the scrollBar */
html {
	-webkit-locale: 'fr' !important;
}

::-webkit-scrollbar {
	width: 7px;
	height: 7px;
	margin-right: 5px;
}
::-webkit-scrollbar-thumb {
	background: #dadada;
	border-radius: 10px;
	margin-right: 5px;
}
::-webkit-scrollbar-thumb:hover {
	background: #bdbdbd;
	margin-right: 5px;
}
::-webkit-scrollbar-track {
	background: #eeeeee;
	border-radius: 10px;
	margin: 15px;
	margin-right: 5px;
}

a {
	text-decoration: none;
	color: inherit;
}


.smallScrollbar::-webkit-scrollbar {
	width: 10px!important;
	height: 13px;
	margin-right: 5px;
}
.smallScrollbar::-webkit-scrollbar-thumb {
	background: #dadada;
	border-radius: 10px;
	margin-right: 5px;
}
.smallScrollbar::-webkit-scrollbar-thumb:hover {
	background: #bdbdbd;
	margin-right: 5px;
}
.smallScrollbar::-webkit-scrollbar-track {
	background: #eeeeee;
	border-radius: 10px;
	margin: 15px;
	margin-right: 5px;
}

.smallScrollbar::-webkit-scrollbar-track {
    margin-top: 3px;
    margin-bottom: 0px;
   
}